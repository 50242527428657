const inputFieldData = [
    {
        id: "firstName",
        md: "6",
        label: "fName",
        type: "text"
    },
    {
        id: "lastName",
        md: "6",
        label: "lName",
        type: "text"
    },
    {
        id: "age",
        md: "4",
        label: "age",
        type: "number"
    },
    {
        id: "email",
        md: "8",
        label: "email",
        type: "text"
    },

]

export default inputFieldData;